import type {LoaderFunctionArgs} from '@remix-run/node'
import {redirect} from '@remix-run/node'
import {Link, Outlet, useLocation} from '@remix-run/react'
import {Button} from '@translated/babele-react'
import {useEffect} from 'react'

import {LoadingProgressBar} from '~/features/LoadingProgressBar'
import {Header} from '~/ui/Header'
import {getSession} from '~/utils/sessions.server'
import {Enterprise1Image, Taxi2Image, TaxiDeveloper1Image} from './Images'

export const loader = async ({request}: LoaderFunctionArgs) => {
  const session = await getSession(request.headers.get('Cookie'))
  if (session.has('token')) return redirect('/dashboard')
  return null
}

const getSVGImage = (path: string) => {
  if (path.match(/\/login\/new-password/)) {
    return <Taxi2Image />
  }
  if (path.match(/\/login\/reset-password/)) {
    return <TaxiDeveloper1Image />
  }
  if (path.match(/\/login/)) {
    return <Enterprise1Image />
  }
  return <Enterprise1Image />
}

export default function LoginLayout() {
  useEffect(() => {
    localStorage.removeItem('token')
    localStorage.removeItem('user-is-logged')
  }, [])

  const location = useLocation()
  const ImageSVG = getSVGImage(location.pathname ?? '')

  return (
    <>
      <LoadingProgressBar />
      <div className="min-h-[100dvh] grid grid-rows-[auto_1fr]">
        <Header className="px-6 py-3">
          <div className="flex flex-row items-center gap-4">
            <span className="hidden md:inline">Don't have an account</span>
            <Button
              variant={{tone: 'primary', mode: 'outline'}}
              render={
                <Link to="/pricing" reloadDocument>
                  Get a license
                </Link>
              }
            />
          </div>
        </Header>
        <main className="grid grid-cols-1 lg:grid-cols-2">
          <div className="p-8 grid">
            <Outlet />
          </div>
          <div className="bg-notice-100 hidden lg:grid place-items-center">
            {ImageSVG}
          </div>
        </main>
      </div>
    </>
  )
}
